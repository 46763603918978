import React from "react"
import { Fade } from "react-awesome-reveal"

const Iframe = ({ slice }) => {
  const iframeUrl = slice.primary.iframe_url

  return (
    <section className="py-12 border-t-2 md:border-none lg:py-20 border-grey-25">
      <div className="container-fluid">
        <iframe id="pod-point-iframe" title="Pop Point iframe" src={ iframeUrl }></iframe>
      </div>
    </section>
  )
}

export default Iframe
