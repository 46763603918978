import React from "react"
import Hero from "./Hero"
import BlockPromoCards from "./BlockPromoCards"
import Banner from "./Banner"
import Stats from "./Stats"
import CardNewsCarousel from "./CardNewsCarousel"
import QuickLinks from "./QuickLinks"
import DocumentList from "./DocumentList"
import DocumentTabs from "./DocumentTabs"
import BlockTabs from "./BlockTabs"
import RichText from "./RichText"
import AccordionBlock from "./AccordionBlock"
import Quote from "./Quote"
import Iframe from "./Iframe"
import SharePriceHighlights from "./SharePriceHighlights"
import SharePriceTable from "./SharePriceTable"
import ButtonBlock from "./ButtonBlock"
import { useDocuments } from "../hooks/useDocuments"

const SliceZone = ({ sliceZone }) => {
  const sliceComponents = {
    hero: Hero,
    card_promo: BlockPromoCards,
    banner: Banner,
    stats: Stats,
    news: CardNewsCarousel,
    quick_links: QuickLinks,
    document_list: DocumentList,
    document_tabs:  DocumentTabs,
    tabs: BlockTabs,
    rich_text: RichText,
    accordion: AccordionBlock,
    quote: Quote,
    iframe: Iframe,
    share_price_highlights: SharePriceHighlights,
    share_price_table: SharePriceTable,
    button_block: ButtonBlock
  }

  const documents = useDocuments()

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]

    if (SliceComponent && slice.slice_type !== "document_list") {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    } else {
      return (
        <section
          className="pb-12 container-fluid lg:pb-20"
          key={`slice-${index}`}
        >
          <SliceComponent documents={documents.allPrismicDocuments.edges} />
        </section>
      )
    }
    // return null
  })

  return <div className="slice-zone">{sliceZoneContent}</div>
}

export default SliceZone
