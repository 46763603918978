import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"
// import Animation from "./Animation"
import Market from "../assets/market.svg"

const Stats = ({ slice }) => {
  const sectionTitle = slice.primary.section_title.text
  const freeEntryCopy = slice.primary.free_entry_copy.text
  const buttonLink = slice.primary.button_link
  const buttonCopy = slice.primary.button_copy
  const stats = slice.items

  return (
    <Fade triggerOnce>
      <section className="py-12 border-t-2 md:border-none lg:py-20 border-grey-25">
        <div className="container-fluid">
          <h2 className="text-center">{sectionTitle}</h2>
          <div className="mx-auto mb-11 2xl:max-w-screen-lg">
            {/* <Animation animationName="SuperMarketAnimation" /> */}
            {/* <Animation /> */}
            <Market />
          </div>
          <div className="grid gap-4 mt-10 md:grid-cols-2 lg:gap-12 lg:grid-cols-4">
            {stats.map((stat, index) => {
              const title = stat.stat_title.text
              const copy = stat.stat_copy.text
              return (
                <div className="text-center" key={`stat-${index}`}>
                  <h2 className="mb-1 text-green-100 lg:mb-2">{title}</h2>
                  <div className="font-bold">{copy}</div>
                </div>
              )
            })}
          </div>
          <div className="mt-12 text-center text-grey-50">{freeEntryCopy}</div>

          <div className="mt-8 text-center">
            <Link
              className="text-white duration-500 ease-in-out bg-green-100 border-green-100 btn hover:bg-white hover:text-green-100 undefined"
              to={buttonLink.uid || buttonLink.url}
              target={buttonLink.target}
            >
              <span>{buttonCopy}</span>
            </Link>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default Stats
