import React from "react"
import { FiDownload } from "react-icons/fi"
import Button from "./Button"

const DocumentListItem = ({ document }) => {
  // console.log("document", document)

  const { url } = document.node.data.document_file
  const { uid } = document.node
  const { date } = document.node.data
  const title = document.node.data.document_title.text
  const fileType = url.split(".").pop()

  return (
    <div className="px-4 py-3 document-list__item md:px-6 md:grid md:grid-cols-12 even:bg-grey-25">
      <div className="flex items-center col-span-3 lg:col-span-2 body-small">
        {date}
      </div>
      <div className="flex items-center col-span-6 col-start-4">
        <a
          className="mt-2 mb-4 no-underline text-grey-100 md:my-0"
          href={`/${uid}`}
          target="_blank"
          rel="noreferrer"
        >
          <h6>{title}</h6>
        </a>
      </div>
      <div className="flex items-center col-span-2 col-start-11 md:justify-end">
        <Button
          url={`/${uid}`}
          target="_blank"
          copy={fileType}
          type="tertiary"
          extraClasses="uppercase"
        >
          <FiDownload className="inline-block w-6 h-6 ml-2" />
        </Button>
      </div>
    </div>
  )
}

export default DocumentListItem
