import React from "react"
import { Fade } from "react-awesome-reveal"
import Accordion from './Accordion';
import AccordionItem from './AccordionItem';
import '../styles/accordion.css';

const AccordionBlock = ({ slice }) => {  
  const items = Array.from(slice.items);
  
  const accordionItems = items.map(({ accordion_item_title, accordion_item_copy }) => ({ 
    header: accordion_item_title, content: accordion_item_copy.html 
  }));

  return (
    <Fade triggerOnce>
      <section className="my-6 md:my-12">
        <div className="mx-auto lg:max-w-4xl">
          <div className="container-fluid">
            <div className="simple-text">
              <Accordion atomic={true}>
                {accordionItems.map((item, index) => {
                  return (
                    <AccordionItem title={item.header} key={index}>
                      <div
                      className="content"
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      />
                    </AccordionItem>
                  )
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default AccordionBlock
