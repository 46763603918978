import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import { Fade } from "react-awesome-reveal"
import { abbreviateNumber } from "js-abbreviation-number"
class SharePriceTable extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      slice: props.slice,
      priceMeta: props.data.priceFeedMeta,
      twoColTableArray: [],
    }

    this.updatedPriceData = [...props.data.allPriceFeedData.nodes]
    this.twoColTableArray = []
  }

  componentDidMount() {
    // console.log('componentDidMount');

    this.updatedPriceData.unshift(
      {
        "label": "Symbol", 
        "data": `${this.state.priceMeta.exchange}:${this.state.priceMeta.symbol}`
      }
    )
    
    // // Market Cap
    const marketCapObj = this.updatedPriceData.findIndex((obj => obj.dataId === "market_cap"));
    const marketCapData = this.updatedPriceData[marketCapObj].data.replace(/,/g, "") / 100
    const marketCapAbbr = abbreviateNumber(marketCapData, 2, {symbols: ["", "", "m", "bn"]})
    if(!this.updatedPriceData[marketCapObj].data.includes("£")) {
      this.updatedPriceData[marketCapObj].data = `£${marketCapAbbr}`
    }
    
    // Value Change Percent
    const changePercentObj = this.updatedPriceData.findIndex((obj => obj.dataId === "val_change_percent"));
    if(!this.updatedPriceData[changePercentObj].data.includes("%")) {
      this.updatedPriceData[changePercentObj].data = `${this.updatedPriceData[changePercentObj].data}%`
    }
    
    // 2 col separation
    const leftTable = this.updatedPriceData.slice(0, 11);
    const rightTable = this.updatedPriceData.slice(11);  

    this.twoColTableArray.push(leftTable, rightTable)

    this.setState({twoColTableArray: this.twoColTableArray})
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount');
    
    this.setState({
      slice: {},
      priceMeta: {},
      twoColTableArray: [],
    })

    this.updatedPriceData = []
    this.twoColTableArray = []
  }

  render() {
    return (
      <Fade triggerOnce>
        <section className="py-12 lg:py-16">
          <div className="container-fluid">
            <h3>{this.state.slice.primary.share_price_table_title.text}</h3>
            <div className="grid grid-cols-1 gap-4 mt-6 lg:mt-10 md:grid-cols-2 lg:gap-12">
            
                {this.state.twoColTableArray.map((array, index) => {  
                  return (
                    <div key={`col-${index}`}>
                      {array.map((item, index) => {
                        const labelClass = item.label.replace(/\s/g, '-').toLowerCase();

                        if(labelClass !== "market-cap" && labelClass !== "shares-in-issue") {
                          return (
                            <div className={`flex p-3 lg:px-6 lg:py-4 justify-between- w-50 odd:bg-grey-25 ${labelClass}`} key={`row-${index}`}> 
                              <div className="flex-grow w-1/2 font-bold">{item.label}</div>
                              <div className="flex-grow w-1/2">{item.data}</div>
                            </div>
                          )
                        }
                      })}
                    </div>
                  )
                })}
            </div>
          </div>
        </section>
      </Fade>
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
       {
        priceFeedMeta {
          symbol
          exchange
          timestamp
        }
        allPriceFeedData {
          nodes {
            dataId
            data
            label
            type
          }
        }
      }
    `}
    render={(data) => <SharePriceTable data={data} {...props} />}
  />
)
