import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import { Fade } from "react-awesome-reveal"

class SharePriceHighlights extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      priceData: [...props.data.allPriceFeedData.nodes],
      highlightsData: []
    }
  }

  componentDidMount() {

    const current = this.state.priceData.filter(item => item.dataId === "current")[0].data
    const change = this.state.priceData.filter(item => item.dataId === "val_change")[0].data
    const changePercent = this.state.priceData.filter(item => item.dataId === "val_change_percent")[0].data
    const open = this.state.priceData.filter(item => item.dataId === "open")[0].data
    const high = this.state.priceData.filter(item => item.dataId === "intraday_high")[0].data
    const signumChange = change > 0 ? `+${change}` : `${change}`
    
    this.setState({highlightsData: 
      [
        {
          label: "Current Price",
          data: current,
          dataExtra: "GBX",
        },
        {
          label: "Value Change",
          data: signumChange,
          dataExtra: `(${changePercent}%)`,
        },
        {
          label: "Day Open",
          data: open,
          dataExtra: "GBX",
        },
        {
          label: "Day High",
          data: high,
          dataExtra: "GBX",
        },
      ]
    })
  }

  render() {
    return (
      <Fade triggerOnce>
        <section className="py-6 lg:py-12 bg-grey-25">
          <div className="container-fluid">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:gap-12">
              {this.state.highlightsData.map((item, index) => {
                return (
                  <div className="font-bold text-center" key={index}>
                    <div className="text-green-100">
                      <span className="mr-1 text-hero-lg">{item.data}</span>
                      <span className="text-heading-4-lg">{item.dataExtra}</span>
                    </div>
                    <div className="">{item.label}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Fade>
    )
  }

}

export default props => (
  <StaticQuery
    query={graphql`
       {
        allPriceFeedData {
          nodes {
            dataId
            data
            label
            type
          }
        }
      }
    `}
    render={(data) => <SharePriceHighlights data={data} {...props} />}
  />
)
