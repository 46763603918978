import { useStaticQuery, graphql } from "gatsby"

export const useFeaturedDocuments = () => {
  const FeaturedDocumentsDataQuery = useStaticQuery(graphql`
    query FeaturedDocumentsData {
      allPrismicPage {
        edges {
          node {
            data {
              body {
                ... on PrismicPageDataBodyDocumentTabs {
                  items {
                    document_selector {
                      uid
                    }
                    document_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return FeaturedDocumentsDataQuery
}
