import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Plx from "react-plx"

const Hero = ({ slice }) => {
  // console.log("slice", slice)
  const title = slice.primary.hero_title.text
  const copy = slice.primary.hero_copy.text
  const image = slice.primary.hero_image.localFile
  const imageAlt = slice.primary.hero_image.alt
  const pathToImage = getImage(image)

  const parallaxData = [
    {
      start: "self",
      // end: "1000vh",
      duration: "1000vh",
      properties: [
        {
          startValue: -15,
          endValue: 600,
          unit: "vh",
          property: "translateY",
        },
      ],
    },
  ]

  return (
    <section className="relative c-hero bg-grey-25">
      <div className="absolute w-full h-full overflow-hidden">
        <Plx className="MyAwesomeParallax" parallaxData={parallaxData}>
            <GatsbyImage 
              className="h-500 md:h-screen-60 lg:h-screen-115 max-h-800 md:min-h-600" 
              image={pathToImage} 
              alt={imageAlt} 
            />
        </Plx>
      </div>
      <div className="container-fluid h-400 lg:h-600">
        <div className="absolute z-10 w-11/12 text-white transform -translate-y-1/2 md:w-8/12 lg:w-6/12 top-1/2 ">
          <div className="mb-6 hero">{title}</div>
          <h6>{copy}</h6>
        </div>
      </div>
    </section>
  )
}

export default Hero
