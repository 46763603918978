import React from "react"
import { Fade } from "react-awesome-reveal"
import classNames from "classnames"
import { FiArrowRight } from "react-icons/fi"
import Button from "./Button"

const QuickLinks = ({ slice }) => {
  // console.log(slice)
  const links = slice.items

  const gridClass = classNames("grid gap-4 grid-cols-1 lg:gap-12", {
    "md:grid-cols-2": links.length === 2,
    "md:grid-cols-3": links.length === 3,
    "md:grid-cols-4": links.length === 4,
  })

  return (
    <section className="py-12 lg:py-16 bg-grey-25">
      <Fade triggerOnce>
        <div className="container-fluid">
          <div className={gridClass}>
            {links.map((linkItem, index) => {
              const link = linkItem.link
              const { link_icon, link_title } = linkItem

              return (
                <div className="flex flex-col card group" key={`link-${index}`}>
                  <img
                    src={link_icon.url}
                    alt={link_icon.alt}
                    className="mb-3 lg:mb-4"
                    width="40px"
                    height="40px"
                  />
                  <h5 className="mb-3 lg:mb-25">{link_title}</h5>

                  <Button
                    url={`/${link.uid || ``}`}
                    target="_self"
                    copy="View section"
                    type="textLink"
                    extraClasses="mt-auto"
                  >
                    <FiArrowRight className="inline-block w-6 h-6 -ml-4 duration-500 ease-in-out opacity-0 group-hover:ml-1 group-hover:opacity-100" />
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default QuickLinks
