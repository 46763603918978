import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FiExternalLink } from "react-icons/fi"
import Button from "./Button"

const ProfileCard = props => {
  const { profile } = props
  const { name, position, linkedin_link } = profile
  const image = profile.profile_picture.localFile
  const pathToImage = getImage(image)

  function handleClick(event) {
    props.onClick(props.profile)
  }

  return (
    <div className="flex flex-col grid-cols-11 pb-6 mb-2 border-b md:mb-0 md:pb-0 border-grey-10 md:block md:bg-white md:border md:rounded lg:grid md:shadow-card md:border-grey-10">
      <GatsbyImage 
        className="w-10/12 col-span-5 m-4 md:m-8 rounded-full" 
        image={pathToImage} 
        alt={name.text} 
      />
      <div className="flex flex-col flex-grow col-span-6 p-0 md:p-4 lg:p-6">
        <h3>{name.text}</h3>
        <div className="mt-2 mb-4 lg:my-2 body-small">{position.text}</div>
        <div className="flex flex-col mt-auto md:flex-row lg:flex-col">
          <div
            className="flex items-center justify-center w-full h-10 duration-500 ease-in-out border-2 rounded-full btn border-grey-dark text-grey-dark hover:bg-grey-dark hover:text-white"
            onClick={handleClick}
            role="button"
            aria-hidden="true"
          >
            Read bio
          </div>
          {linkedin_link.url ? (
            <div className="mt-2 md:mt-0 md:ml-2 lg:ml-0 lg:mt-2">
              <Button
                url={linkedin_link.url}
                target="_blank"
                copy="LinkedIn"
                type="tertiary"
                extraClasses="justify-center w-full"
              >
                <FiExternalLink className="inline-block w-6 h-6 ml-2" />
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProfileCard
