import React from "react"
import Button from "./Button"
import { FiDownload } from "react-icons/fi"

const CardInternal = ({ publishDate, title, url, fileType, saveToPdf }) => {

  const updatedUrl = (!saveToPdf ? url : url + "/export")
  
  return (
    <div className="flex flex-col h-full card-internal">
      <a
        className="mb-2 no-underline text-grey-100"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <h3>{title}</h3>
      </a>

      <div className="mb-4 lg:mb-0 body-small">{publishDate}</div>

      <div className="mt-auto">
        <Button
          url={updatedUrl}
          target="_blank"
          copy={fileType}
          type="tertiary"
          extraClasses="uppercase"
        >
          <FiDownload className="inline-block w-6 h-6 ml-2" />
        </Button>
      </div>
    </div>
  )
}

export default CardInternal
