import React from "react"
import dayjs from "dayjs"
import { useNewsFeedData } from "../hooks/useNewsFeedData"
import Button from "./Button"
import CardTopIcon from "./CardTopIcon"

const CardPromoNews = ({ slice }) => {
  const priceFeedData = useNewsFeedData()
  const news = priceFeedData.allNewsFeedData.nodes
  const newsCardIcon = slice.primary.card_icon.url
  const newsCardTitle = slice.primary.card_title.text

  news.sort(function(a, b){
    return new Date(b.newsTimestamp) - new Date(a.newsTimestamp);
  });

  return (
    <div className="flex flex-col items-start card lg:min-h-400 lg:h-full md:flex-row lg:flex-col">
      <CardTopIcon icon={newsCardIcon} title={newsCardTitle} />

      <div className="flex flex-col w-full h-full md:w-4/6 lg:w-full">
        {news.slice(0, 3).map((newsItem, index) => {
          
          const newsDate = newsItem.newsTimestamp
          const newsTitle = newsItem.newsTitle.replace("&amp;", "&")
          const newsLink = newsItem.newsLink
          const formattedDate = dayjs(newsDate).format('D MMMM YYYY')

          return (
            <div className="pb-3 mb-3 border-b border-grey-10" key={index}>
              <a
                href={newsLink}
                target="_blank"
                rel="noreferrer"
                className="no-underline text-grey-100"
              >
                <h6 className="mb-1 text-heading-4-sm lg:text-heading-6-lg">
                  {newsTitle}
                </h6>
              </a>
              <div className="body-small">{formattedDate}</div>
            </div>
          )
        })}
        <div className="mt-auto">
          <Button
            url={`/regulatory-news`}
            target="_blank"
            copy="View all"
            type="tertiary"
            extraClasses="lg:mt-6"
          >
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CardPromoNews
