import React from "react"

const CardTopIcon = ({ icon, title }) => {
  // console.log("icon", icon)

  return (
    <div className="flex items-center mb-4 md:mr-4 md:mb-0 md:w-2/6 lg:mr-0 lg:mb-6 lg:w-full">
      <div>
        <img src={icon} alt={title} width="40px" height="40px" />
      </div>
      <div className="ml-3 font-bold uppercase body-small-lg">{title}</div>
    </div>
  )
}

export default CardTopIcon
