import { useStaticQuery, graphql } from "gatsby"

export const useDocuments = () => {
  const documentsQuery = useStaticQuery(graphql`
    query Documents {
      allPrismicDocuments (
        filter: {data: {document_type: {ne: "Constitutional Documents, Policies"}}}
        sort: { order: DESC, fields: first_publication_date }
      ) {
        edges {
          node {
            uid
            data {
              date(formatString: "D MMMM YYYY")
              document_file {
                url
              }
              document_title {
                text
              }
              document_type
            }
          }
        }
      }
    }
  `)

  return documentsQuery
}
