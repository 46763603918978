import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"
import CardInternal from "./CardInternal"
import DocumentIcon from "../assets/icons/document.svg"
import DocIcon from "../assets/icons/doc.svg"
import Mp3Icon from "../assets/icons/mp3.svg"
import Mp4Icon from "../assets/icons/mp4.svg"
import PdfIcon from "../assets/icons/pdf.svg"
import XlsIcon from "../assets/icons/xls.svg"
import ZipIcon from "../assets/icons/zip.svg"

const BlockFeaturedCards = ({ documents }) => {
  // console.log('documents', documents);
  const uidArr = documents.map(document => document.document_selector.uid)

  const documentsData = useStaticQuery(graphql`
    {
      allPrismicDocuments {
        edges {
          node {
            uid
            data {
              date(formatString: "D MMMM YYYY")
              document_file {
                url
              }
              document_title {
                text
              }
            }
          }
        }
      }
    }
  `).allPrismicDocuments.edges.filter(edge => uidArr.includes(edge.node.uid))

  const iconClassNames = "w-14 h-14 md:w-20 md:h-20 lg:w-25 lg:h-25"

  const getIcon = (fileType) => {
    switch(fileType) {
      case "doc": return <DocIcon className={iconClassNames} />
      case "mp3": return <Mp3Icon className={iconClassNames} />
      case "mp4": return <Mp4Icon className={iconClassNames} />
      case "pdf": return <PdfIcon className={iconClassNames} />
      case "xls": return <XlsIcon className={iconClassNames} />
      case "zip": return <ZipIcon className={iconClassNames} />
      default: return <DocumentIcon className={iconClassNames} />
    }
  }

  // console.log('documentsData', documentsData)

  return (
    <Fade triggerOnce>
      <div className="px-0 mb-20 -mt-6 container-fluid"> 
        <h2 className="pt-6 mb-6 md:pt-0">Featured</h2>
        <div className="grid gap-4 lg:gap-12 lg:grid-cols-2">
          {documentsData.map((item, index) => {
            
            const image = documents[index].document_image.localFile
            const pathToImage = getImage(image)
            const documentDate = item.node.data.date
            const documentTitle = item.node.data.document_title.text
            const { url } = item.node.data.document_file
            const { uid } = item.node
            const fileType = url.split(".").pop()

            return (
              <div key={index}>
                <div className="grid grid-cols-12 card-no-padding lg:h-320">
                  <div className="flex items-center justify-center col-span-4 bg-grey-25 lg:col-span-6">
                    {image
                      ? <GatsbyImage className="h-full" image={pathToImage} alt={documentTitle} />
                      : getIcon(fileType)
                    }
                  </div>
                  <div className="col-span-8 p-6 lg:col-span-6">
                    <CardInternal
                      publishDate={documentDate}
                      title={documentTitle}
                      url={`/${uid}`}
                      fileType={fileType}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Fade>
  )
}

export default BlockFeaturedCards
