import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"
import Plx from "react-plx"
import { FiExternalLink } from "react-icons/fi"
import Button from "./Button"

const Banner = ({ slice }) => {
  const data = slice.primary
  const position = data.banner_position
  const copy = data.banner_copy.text
  const buttonCopy = data.banner_button_copy.text
  const buttonLink = data.banner_button_link
  const buttonIcon = data.button_icon
  const image = data.banner_image.localFile
  const imageAlt = data.banner_image.alt || "banner"
  const pathToImage = getImage(image)
  
  const parallaxData = [
    {
      start: "self",
      duration: "1000vh",
      properties: [
        {
          startValue: -20,
          endValue: 100,
          unit: "vh",
          property: "translateY",
        },
      ],
    },
  ]
  
  return (
    <Fade triggerOnce>
      <section className="relative mb-12 overflow-hidden md:mb-0">
        <div className="w-full overflow-hidden md:absolute h-240 md:h-400">
          <Plx className="MyAwesomeParallax" parallaxData={parallaxData}>
            <GatsbyImage 
              className="h-screen-60 md:h-screen-70 lg:h-screen-80 2xl:h-screen" 
              image={pathToImage} 
              alt={imageAlt}
            />
          </Plx>
        </div>
        <div className="container-fluid md:h-300 lg:h-400">
          <div
            className={`z-10 pt-6 bg-white md:absolute md:transform md:w-7/12 md:-translate-y-1/2 md:rounded md:top-1/2 md:p-6 lg:w-5/12 lg:p-12 ${
              position === "left" ? "left-12" : "right-12"
            }`}
          >
            <h2 className="mb-4 md:mb-6">{copy}</h2>
            <div className="text-center md:text-left">
              <Button
                url={buttonLink.uid || buttonLink.url}
                target={buttonLink.target}
                copy={buttonCopy}
                type="primary"
              >
                {buttonIcon && <FiExternalLink className="inline-block w-6 h-6 ml-2 " />}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default Banner
