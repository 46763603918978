import { useStaticQuery, graphql } from "gatsby"

export const usePoliciesDocuments = () => {
  const documentsQuery = useStaticQuery(graphql`
    query PoliciesDocuments {
      allPrismicDocuments(sort: { order: DESC, fields: data___date }) {
        edges {
          node {
            uid
            data {
              date(formatString: "D MMMM YYYY")
              document_file {
                url
              }
              document_title {
                text
              }
              document_type
            }
          }
        }
      }
    }
  `).allPrismicDocuments.edges.filter(
    edge => edge.node.data.document_type === "Policies"
  )

  return documentsQuery
}
