import React, { useState } from "react"

const useSortableData = (items = [], config = null) => {
  const [sortConfig, setSortConfig] = useState(config)

  const isDate = date => {
    return !isNaN(new Date(date).getDate())
  }

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items]

    if (sortConfig !== null) {
      const isDateSort = isDate(sortableItems[0].node.data[sortConfig.key])

      sortableItems.sort((a, b) => {
        if (isDateSort) {
          a = new Date(a.node.data[sortConfig.key])
          b = new Date(b.node.data[sortConfig.key])
        } else {
          a = a.node[sortConfig.key]
          b = b.node[sortConfig.key]
        }

        if (a < b) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (a > b) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const requestSort = key => {
    let direction = "ascending"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  // console.log(items)

  return { items: sortedItems, requestSort, sortConfig }
}

export default useSortableData
