import React from "react"
import Button from "./Button"

const ButtonBlock = ({ slice }) => {
  console.log("slice", slice)

  const buttonCopy = slice.primary.button_copy
  const buttonLink = slice.primary.button_link

  return (
    <section className="relative pb-12 bg-white lg:pb-20">
      <div className="text-center">
        <Button
          url={`/${buttonLink.uid || buttonLink.url}`}
          target={buttonLink.target}
          copy={buttonCopy}
          type="primary"
        >
        </Button>
      </div>
    </section>
  )
}

export default ButtonBlock
