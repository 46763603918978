import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CardTopIcon from "./CardTopIcon"
import CardInternal from "./CardInternal"

const CardPromoDocuments = ({ document }) => {
  const documentDate = document.data.date
  const documentTitle = document.data.document_title.text
  const { uid } = document
  const { url } = document.data.document_file
  const fileType = url.split(".").pop()

  const documentIconQuery = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "document" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <div className="flex flex-col items-start card lg:min-h-400 lg:h-full md:flex-row lg:flex-col group">
      <CardTopIcon
        icon={documentIconQuery.allFile.edges[0].node.publicURL}
        title="Document"
      />
      <CardInternal
        publishDate={documentDate}
        title={documentTitle}
        url={`${uid}`}
        fileType={fileType}
      />
    </div>
  )
}

export default CardPromoDocuments
