import React, { useState } from "react"
import ReactModal from "react-modal"
import ProfileCard from "./ProfileCard"
import ProfileModal from "./ProfileModal"

const ProfileCardList = ({ profiles }) => {
  // console.log(profiles)

  const [activeProfile, setActiveProfile] = useState()

  const [isModalOpen, setModalOpen] = useState(false)

  const handleClick = function (profile) {
    setModalOpen(!isModalOpen)
    setActiveProfile(profile)
    // console.log("profile", profile)
  }

  // Fired when user presses escape or clicks overlay
  const handleClose = function () {
    setModalOpen(!isModalOpen)
  }

  // Fix: "App element is not defined" error by binding to div at root level of app
  ReactModal.setAppElement("#___gatsby")

  return (
    <div>
      <ReactModal
        closeTimeoutMS={300}
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        className="relative md:mx-auto md:transform md:-translate-y-1/2 md:w-4/5 md:max-w-screen-lg md:top-1/2"
      >
        <ProfileModal profile={activeProfile} handleClose={handleClose} />
      </ReactModal>
      <div className="grid grid-cols-2 gap-4 lg:gap-12">
        {profiles.map((profile, index) => {
          return (
            <ProfileCard
              profile={profile.node.data}
              key={`profile-${index}`}
              onClick={handleClick}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ProfileCardList
