import React from "react"
import { Fade } from "react-awesome-reveal"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useNewsFeedData } from "../hooks/useNewsFeedData"
import useWindowSize from "../hooks/useWindowSize"
import useSwiperRef from "../hooks/useSwiperRef.js"
import "swiper/swiper.min.css"
import CardNews from "./CardNews"
import Button from "./Button"

import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

SwiperCore.use([Navigation])

const CardNewsCarousel = ({ slice }) => {

  const priceFeedData = useNewsFeedData()
  const size = useWindowSize()
  const [nextEl, nextElRef] = useSwiperRef()
  const [prevEl, prevElRef] = useSwiperRef()
  const sectionTitle = slice.primary.section_title.text
  const news = priceFeedData.allNewsFeedData.nodes
  const spaceBetween = size.width >= 1024 ? 48 : 16

  news.sort(function(a, b){
    return new Date(b.newsTimestamp) - new Date(a.newsTimestamp);
  });

  return (
    <section className="py-12 overflow-hidden lg:py-20 bg-grey-25">
      <Fade triggerOnce>
        <h2 className="text-center">{sectionTitle}</h2>
        <div className="container-fluid">
          <Swiper
            className="overflow-visible"
            spaceBetween={spaceBetween}
            slidesPerView={"auto"}
            navigation={{
              prevEl,
              nextEl,
            }}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={swiper => console.log(swiper)}
          >
            {news.map((newsItem, index) => {
              return (
                <SwiperSlide
                  className="w-full my-6 lg:my-12 md:w-328 lg:w-348"
                  key={index}
                >
                  <CardNews data={newsItem} />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className="flex flex-col justify-between w-full md:flex-row">
            <div className="flex justify-center order-2 md:order-1">
              <Button
                  url={`/email-alerts`}
                  target="_self"
                  copy="Subscribe to email alerts"
                  type="primary"
                  extraClasses="mt-6 md:mr-5 md:mt-0"
                >
                </Button>
            </div>
            <div className="flex justify-between order-1 md:order-2">
              <Button
                url={`/regulatory-news`}
                target="_self"
                copy="View all"
                type="tertiary"
                extraClasses="mr-5"
              >
              </Button>
              <div className="flex">
                <div
                  className="px-3 py-2 bg-white rounded-l-full swiper-button"
                  ref={prevElRef}
                >
                  <FiChevronLeft className="w-6 h-6" />
                </div>
                <div
                  className="px-3 py-2 bg-white rounded-r-full swiper-button"
                  ref={nextElRef}
                >
                  <FiChevronRight className="w-6 h-6" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </Fade>
    </section>
  )
}

export default CardNewsCarousel
