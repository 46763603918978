import React from "react"
import { Fade } from "react-awesome-reveal"
import useSortableData from "../hooks/useSortableData"
import DocumentListHeader from "./DocumentListHeader"
import DocumentListItem from "./DocumentListItem"

const DocumentList = ({ documents }) => {
  const { items, requestSort, sortConfig } = useSortableData(documents)

  const getClassNamesFor = name => {
    if (!sortConfig && name === "date") {
      return "descending"
    } else if (!sortConfig) {
      return ""
    }
    return sortConfig.key === name ? sortConfig.direction : ""
  }

  const handleRequestSort = key => {
    requestSort(key)
  }

  return (
    <Fade triggerOnce>
      <>
        <DocumentListHeader
          onClick={handleRequestSort}
          sortDirectionDate={getClassNamesFor("date")}
          sortDirectionTitle={getClassNamesFor("uid")}
        />
        {items.map((document, index) => {
          return (
            <DocumentListItem
              document={document}
              className="even:bg-grey-25"
              key={`document-${index}`}
            />
          )
        })}
      </>
    </Fade>
  )
}

export default DocumentList
