import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { Fade } from "react-awesome-reveal"
import ProfileCardList from "./ProfileCardList"
import DocumentList from "../components/DocumentList"
import { useConstitutionalDocuments } from "../hooks/useConstitutionalDocuments"
import { usePoliciesDocuments } from "../hooks/usePoliciesDocuments"

const BlockTabs = () => {
  const profiles = useStaticQuery(graphql`
    {
      allPrismicProfile(sort: { fields: data___order, order: ASC }) {
        edges {
          node {
            data {
              profile_picture {
                url
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
              }
              name {
                text
              }
              position {
                text
              }
              bio {
                text
              }
              linkedin_link {
                url
                target
              }
              team
            }
          }
        }
      }
    }
  `)

  const executiveTeamStr = "Executive Team"
  const boardOfDirectorsStr = "Board of Directors"
  const constitutionalDocumentsStr = "Constitutional Documents"
  const policiesDocumentsStr = "Policies"

  const executiveProfiles = profiles.allPrismicProfile.edges.filter(edge =>
    edge.node.data.team.includes(executiveTeamStr)
  )

  const directorsProfiles = profiles.allPrismicProfile.edges.filter(edge =>
    edge.node.data.team.includes(boardOfDirectorsStr)
  )

  const constitutionalDocuments = useConstitutionalDocuments()
  const policiesDocuments = usePoliciesDocuments()

  return (
    <Fade triggerOnce>
      <section className="pb-12 md:pb-20">
        <div className="container-fluid">
          <Tabs>
            <TabList>
              {executiveProfiles.length > 0 && <Tab>{executiveTeamStr}</Tab>}
              {directorsProfiles.length > 0 && <Tab>{boardOfDirectorsStr}</Tab>}
              {constitutionalDocuments.length > 0 && <Tab>{constitutionalDocumentsStr}</Tab>}
              {policiesDocuments.length > 0 && <Tab>{policiesDocumentsStr}</Tab>}
            </TabList>

            {executiveProfiles.length > 0 &&
              <TabPanel>
                <ProfileCardList profiles={executiveProfiles} />
              </TabPanel>
            }
            {directorsProfiles.length > 0 &&
              <TabPanel>
                <ProfileCardList profiles={directorsProfiles} />
              </TabPanel>
            }
            {constitutionalDocuments.length > 0 &&
              <TabPanel>
                <DocumentList documents={constitutionalDocuments} />
              </TabPanel>
            }
            {policiesDocuments.length > 0 &&
              <TabPanel>
                <DocumentList documents={policiesDocuments} />
              </TabPanel>
            }
          </Tabs>
        </div>
      </section>
    </Fade>
  )
}

export default BlockTabs
