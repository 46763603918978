import React from "react"
import { CgArrowDown, CgArrowUp } from "react-icons/cg"

const DocumentListHeader = props => {
  
  const handleRequestSort = (key) => {
    props.onClick(key)
  }

  return (
    <div className="grid grid-cols-12 px-4 py-3 md:px-6">
      <div
        className={`flex items-center col-span-3 font-bold uppercase cursor-pointer md:col-span-2 sortby ${props.sortDirectionDate}`}
        onClick={() => handleRequestSort("date")}
        role="button"
        tabIndex="0"
      >
        Date
        <CgArrowUp />
      </div>
      <div
        className={`flex items-center col-span-6 col-start-4 font-bold uppercase cursor-pointer sortby ${props.sortDirectionTitle}`}
        onClick={() => handleRequestSort("uid")}
        role="button"
        tabIndex="0"
      >
        Title
        <CgArrowDown />
      </div>
      <div className="items-center justify-end hidden col-span-2 col-start-11 font-bold uppercase md:flex">
        Downloads
      </div>
    </div>
  )
}

export default DocumentListHeader
