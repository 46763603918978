import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FiX, FiExternalLink } from "react-icons/fi"
import Button from "./Button"

const ProfileModal = props => {
  const { profile } = props
  const { name, position, linkedin_link } = profile
  const image = profile.profile_picture.localFile
  const imageAlt = profile.profile_picture.alt
  const pathToImage = getImage(image)

  function handleClose() {
    props.handleClose()
  }

  return (
    <div className="h-screen p-6 overflow-x-auto bg-white md:h-auto lg:py-12 lg:px-25 card">
      <div
        onClick={handleClose}
        className="relative w-100"
        role="button"
        aria-hidden="true"
      >
        <FiX className="absolute right-0 w-6 h-6 cursor-pointer" />
      </div>
      <div className="grid-cols-12 gap-4 md:grid lg:gap-12">
        <div className="col-span-4">
          <GatsbyImage 
            className="rounded w-146 md:w-full" 
            image={pathToImage} 
            alt={imageAlt} 
          />
        </div>
        <div className="col-span-8">
          <h3 className="mb-1 lg:mb-3">{name.text}</h3>
          <div className="mb-4 body-small">{position.text}</div>
          <div className="mb-4">
            <p>{profile.bio.text}</p>
          </div>
          {linkedin_link.url ? (
            <div className="mt-2">
              <Button
                url={linkedin_link.url}
                target="_blank"
                copy="LinkedIn"
                type="tertiary"
                rel="noreferrer"
              >
                <FiExternalLink className="inline-block w-6 h-6 ml-2" />
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProfileModal
