import React from "react"
import { Link } from "gatsby"
import { usePriceFeedData } from "../hooks/usePriceFeedData"
import CardTopIcon from "./CardTopIcon"
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";

const CardPromoSharePrice = ({ slice }) => {

  const newsCardIcon = slice.primary.share_price_card_icon.url
  const newsCardTitle = slice.primary.share_price_card_title.text
  const priceFeedData = usePriceFeedData()
  const priceFeedDataItems = priceFeedData.allPriceFeedData.nodes
  
  const current = priceFeedDataItems.filter(item => item.dataId === "current")[0].data
  const currency = priceFeedDataItems.filter(item => item.dataId === "currency")[0].data
  const change = priceFeedDataItems.filter(item => item.dataId === "val_change")[0].data
  const volume = priceFeedDataItems.filter(item => item.dataId === "intraday_vol")[0].data
  const high = priceFeedDataItems.filter(item => item.dataId === "intraday_high")[0].data

  const signum = change > 0 ? '+' : ''
  const isPositive = change > 0

  const footer = slice.primary.share_price_card_footer.html

  return (
    <Link
      className="no-underline text-grey-100"
      to="/share-price"
      target="_self"
      rel="noreferrer"
    >
      <div className="flex flex-col items-start card lg:min-h-400 lg:h-full md:flex-row lg:flex-col">
        <CardTopIcon icon={newsCardIcon} title={newsCardTitle} />
        <div className="flex flex-col w-full h-full md:w-4/6 lg:w-full">  
          <div className="mb-4 font-bold">
            <span className="mr-1 text-hero-lg">{current}</span>
            <span className="text-heading-4-lg">{currency}</span>
          </div>
          <div className="flex justify-between w-full mb-4">
            <div>Day Change</div>
            <div className="flex items-center font-bold">
              {isPositive
                ? <GoTriangleUp className="w-3 h-3 ml-2 mr-1 text-green-100" />
                : <GoTriangleDown className="w-3 h-3 ml-2 mr-1 text-danger" />
              }
              {signum}{change}
            </div>
          </div>
          <div className="flex justify-between w-full mb-4">
            <div>Volume</div>
            <div className="font-bold">{volume}</div>
          </div>
          <div className="flex justify-between w-full mb-4">
            <div>Day High</div>
            <div className="font-bold">{high}</div>
          </div>
          
          <div className="w-full pt-6 mt-auto border-t border-grey-10">
            <div
              className="body-small"
              dangerouslySetInnerHTML={{
                __html: footer,
              }}
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default CardPromoSharePrice
