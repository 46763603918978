import React from "react"
import dayjs from "dayjs"
import CardInternal from "./CardInternal"

const CardNews = ({ data }) => {
  // console.log('data', data)
  const newsDate = dayjs(data.newsTimestamp).format('D MMMM YYYY')
  const newsTitle = data.newsTitle
  const url = data.newsLink

  return (
    <div className="card group h-200 lg:h-280">
      <CardInternal
        publishDate={newsDate}
        title={newsTitle}
        url={url}
        fileType="pdf"
        saveToPdf={true}
      />
    </div>
  )
}

export default CardNews
