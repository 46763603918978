import React from "react"
import { Fade } from "react-awesome-reveal"

const RichText = ({ slice }) => {
  // console.log(slice)

  const copy = slice.primary.copy

  return (
    <Fade triggerOnce>
      <section className="my-6 md:my-12">
        <div className="mx-auto lg:max-w-4xl">
          <div className="container-fluid">
            <div className="simple-text">
              <div dangerouslySetInnerHTML={{ __html: copy.html }} />
            </div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default RichText
