import { useStaticQuery, graphql } from "gatsby"

export const useNewsFeedData = () => {
  const newsFeedDataQuery = useStaticQuery(graphql`
    query NewsFeedData {
      allNewsFeedData {
        nodes {
          newsTitle
          newsDescription
          newsCategory
          newsLink
          newsId
          newsTimestamp
        }
      }
    }
  `)

  return newsFeedDataQuery
}
