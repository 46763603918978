import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { Fade } from "react-awesome-reveal"
import BlockFeaturedCards from "./BlockFeaturedCards"
import DocumentList from "../components/DocumentList"
import { useFeaturedDocuments } from "../hooks/useFeaturedDocuments"

const DocumentTabs = () => {
  const featuredDocumentsData = useFeaturedDocuments()
  const documentsData = useStaticQuery(graphql`
    {
      allPrismicDocuments(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            uid
            data {
              date(formatString: "D MMMM YYYY")
              document_file {
                url
              }
              document_title {
                text
              }
              document_type
            }
          }
        }
      }
    }
  `)

  if (!documentsData.allPrismicDocuments.edges.length > 0) return null

  const documents = documentsData.allPrismicDocuments.edges.filter(
    document =>
      document.node.data.document_type !== "Constitutional Documents" &&
      document.node.data.document_type !== "Policies"
  )

  const featuredDocuments =
    featuredDocumentsData.allPrismicPage.edges[0].node.data

  const originalCategories = [
    ...new Set(documents.map(item => item.node.data.document_type)),
  ].reverse()

  const fullCategories = [...originalCategories].filter(
    category =>
      category !== "Constitutional Documents" && category !== "Policies"
  )
  const shortCategories = [...originalCategories].filter(
    category =>
      category !== "Constitutional Documents" && category !== "Policies"
  )

  shortCategories.unshift("All")

  console.log("documents", documents)
  console.log("originalCategories", originalCategories)
  console.log("fullCategories", fullCategories)
  console.log("shortCategories", shortCategories)

  return (
    <Fade triggerOnce>
      <section className="pb-12 md:pb-20">
        <div className="container-fluid">
          <Tabs>
            <TabList>
              {shortCategories.map((category, index) => {
                return <Tab key={index}>{category}</Tab>
              })}
            </TabList>

            <TabPanel>
              {featuredDocuments.body[0].items !== undefined &&
                featuredDocuments.body[0].items.length > 0 && (
                  <BlockFeaturedCards
                    documents={featuredDocuments.body[0].items}
                  />
                )}
              <DocumentList documents={documents} />
            </TabPanel>

            {fullCategories.map((category, index) => {
              return (
                <TabPanel key={index}>
                  <DocumentList
                    documents={documents.filter(
                      document => document.node.data.document_type === category
                    )}
                  />
                </TabPanel>
              )
            })}
          </Tabs>
        </div>
      </section>
    </Fade>
  )
}

export default DocumentTabs
