import React from "react"
import { Fade } from "react-awesome-reveal"

const Quote = ({ slice }) => {

  const copy = slice.primary.quote_copy
  const caption = slice.primary.quote_caption

  return (
    <Fade triggerOnce>
      <section className="my-6 md:my-12">
        <div className="mx-auto lg:max-w-4xl">
          <div className="container-fluid">
            <div className="simple-text">
              <div className="font-bold text-center text-heading-3-lg">
                {copy}
              </div>
              <div className="mt-2 font-bold text-center">
                {caption}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default Quote
