import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"
import CardPromoSharePrice from "./CardPromoSharePrice"
import CardPromoNews from "./CardPromoNews"
import CardPromoDocuments from "./CardPromoDocuments"

const BlockPromoCards = ({ slice }) => {
  const documentsUidArr = [
    slice.primary.link_to_promo_document_1.uid
  ]

  const documentsData = useStaticQuery(graphql`
    {
      allPrismicDocuments {
        edges {
          node {
            uid
            data {
              date(formatString: "D MMMM YYYY")
              document_file {
                url
              }
              document_title {
                text
              }
            }
          }
        }
      }
    }
  `).allPrismicDocuments.edges.filter(edge => documentsUidArr.includes(edge.node.uid))

  return (
    <section className="relative pb-12 lg:pb-20 bg-grey-25">
      <Fade triggerOnce>
        <div className="container-fluid">
          <div className="grid gap-4 lg:gap-12 lg:grid-cols-3">
            <div className="-mt-20">
              <CardPromoSharePrice slice={slice} />
            </div>
            <div className="lg:-mt-20">
              <CardPromoNews slice={slice} />
            </div>
            <div className="lg:-mt-20">
              {documentsData.length > 0 && <CardPromoDocuments document={documentsData[0].node} />}
            </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default BlockPromoCards
